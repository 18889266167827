exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-fb-js": () => import("./../../../src/pages/fb.js" /* webpackChunkName: "component---src-pages-fb-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kazania-js": () => import("./../../../src/pages/kazania.js" /* webpackChunkName: "component---src-pages-kazania-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-pastor-js": () => import("./../../../src/pages/pastor.js" /* webpackChunkName: "component---src-pages-pastor-js" */),
  "component---src-pages-poczta-js": () => import("./../../../src/pages/poczta.js" /* webpackChunkName: "component---src-pages-poczta-js" */)
}

